<template>
  <div class="goods-page">
    <v-header/>
    <van-pull-refresh v-model="pageData.refreshing" @refresh="loadPageData(1,true,200)">
      <van-list v-show="pageData.total>0"
                offset="50"
                :immediate-check="true"
                :finished="!pageData.hasMore"
                finished-text="灵感到底了~"
                v-model="pageData.loading"
                @load="loadPageData(pageData.page+1,false)">
        <goods-card v-for="item in pageData.rows" :key="item.id" :item="item"
                    @click="goPlanDetail(item)"/>
      </van-list>
      <div class="scroll-empty" v-show="pageData.total<=0">
        <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150" style="margin-bottom: 20px">
        <div>噢！这可能有点儿空 (⊙v⊙)</div>
      </div>
    </van-pull-refresh>
  </div>
</template>


------------------ script ------------------
<script>
import {sleep} from "../../config/util";
import {goodsV2Api} from "../../api/v2/goodsV2Api";
import GoodsCard from "../../components/shop/GoodsCard";
import {nav} from "@/config/nav";

export default {
  components: {GoodsCard},
  data() {
    return {
      pageData: {
        refreshing: false,
        total: 0,
        page: 1,
        size: 10,
        rows: [],
        hasMore: false,
        loading: false
      }
    }
  },

  created() {
    this.loadPageData(1, true, 200);
  },


  methods: {

    goPlanDetail(item){
      nav.goPlanDetail(item.projectPlanId)
    },

    // 加载数据
    async loadPageData(page, clearRecords = false, minLoadingMs) {
      try {
        this.$loading('努力追溯中...');
        await sleep(minLoadingMs || 0);
        const params = {
          page,
          size: this.pageData.size,
        };

        const res = await goodsV2Api.search(params);
        this.pageData.page = res.page;
        this.pageData.size = res.size;
        this.pageData.total = res.total;
        if (clearRecords) {
          this.pageData.rows = res.rows;
        } else {
          this.pageData.rows = [...this.pageData.rows, ...res.rows];
        }
      } finally {
        this.pageData.hasMore = this.pageData.page * this.pageData.size < this.pageData.total;
        this.pageData.refreshing = false;
        this.pageData.loading = false
        this.$loading.close();
      }
    }
  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.goods-page {
  padding: 40px 0 0 0;
  background: #f3f5f7;
  min-height: calc(100vh - 40px);
}
</style>
